<template>
  <div class="facturas mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Listado de facturas </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :loading="loading_t"
              :search="search"
              :items="lista"
              hide-default-footer
              :items-per-page="perPage"
              :page.sync="page"
            >
              <template v-slot:top>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-expansion-panels flat>
                      <v-expansion-panel @click="filter_date = !filter_date">
                        <v-expansion-panel-header
                          disable-icon-rotate
                          style="padding: 0px 5px"
                        >
                          <span style="color: #1976d2 !important">
                            Filtrar fechas
                          </span>
                          <template v-slot:actions>
                            <v-checkbox
                              disbled
                              readonly
                              v-model="filter_date"
                            ></v-checkbox>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row no-gutter>
                            <v-col cols="12" md="6" sm="6">
                              <v-menu
                                v-model="menu_inicio"
                                :close-on-content-click="false"
                                :nudge-right="20"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="date.inicio"
                                    outlined
                                    rounded
                                    dense
                                    label="Fecha inicial"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  scrollable
                                  v-model="date.inicio"
                                  locale="es-co"
                                  color="primary"
                                  first-day-of-week="1"
                                  @input="menu_inicio = false"
                                  @change="loadFacturas()"
                                >
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" md="6" sm="6">
                              <v-menu
                                v-model="menu_fin"
                                :close-on-content-click="false"
                                :nudge-right="20"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="date.fin"
                                    outlined
                                    rounded
                                    dense
                                    label="Fecha final"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  scrollable
                                  v-model="date.fin"
                                  locale="es-co"
                                  color="primary"
                                  first-day-of-week="1"
                                  @input="menu_fin = false"
                                  @change="loadFacturas()"
                                >
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="10" md="5" sm="10">
                    <v-text-field
                      outlined
                      rounded
                      dense
                      label="Buscar"
                      v-model="search"
                      @keydown.enter.prevent="loadFacturas"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" md="1" sm="2">
                    <v-btn
                      small
                      fab
                      elevation="0"
                      color="primary"
                      @click="loadFacturas"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <div style="display: flex">
                      <v-subheader> Facturas X pag </v-subheader>
                      <div style="width: 120px">
                        <v-select
                          :items="itemsPerPage"
                          v-model="perPage"
                          outlined
                          dense
                          @change="setItemsPerPage"
                        >
                        </v-select>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="2" sm="2">
                    <v-btn dark color="black" @click="downloadFacturas()">
                      Descargar
                      <v-icon right>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-pagination
                      dense
                      v-model="page"
                      :length="lengthPagination"
                      :total-visible="6"
                      @input="changePage"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.folio="{ item }">
                <strong>
                  {{ item.folio }}
                </strong>
              </template>
              <template v-slot:item.total="{ item }">
                {{ item.total | currency }}
              </template>
              <template v-slot:item.nota="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      color="error"
                      v-if="parseInt(item.notas) > 0"
                      v-on="on"
                      v-bind="attrs"
                    >
                      {{ item.notas }} Notas...
                    </v-chip>
                  </template>
                  <span
                    >La factura cuenta con {{ item.notas }} Notas Credito</span
                  >
                </v-tooltip>
              </template>
              <template v-slot:item.acciones="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <router-link :to="'/detallefactura/' + item.id">
                      <v-btn color="primary" fab small v-on="on" v-bind="attrs">
                        <v-icon>mdi-clipboard-text-search </v-icon>
                      </v-btn>
                    </router-link>
                  </template>
                  <span>Ver factura</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
export default {
  name: "facturas",
  data: () => ({
    filter_date: false,
    perPage: 25,
    page: 1,
    paginationServe: {
      total: 0,
      perPage: 25,
    },
    date: {
      inicio: moment().startOf("month").format("YYYY-MM-DD"),
      fin: moment().format("YYYY-MM-DD"),
    },
    menu_inicio: false,
    menu_fin: false,
    loading_t: false,
    search: "",
    itemsPerPage: [
      {
        text: 10,
        value: 10,
      },
      {
        text: 25,
        value: 25,
      },
      {
        text: 50,
        value: 50,
      },
      {
        text: 100,
        value: 100,
      },
      {
        text: 500,
        value: 500,
      },
    ],
    headers: [
      {
        text: "Fecha factura",
        value: "fecha",
        width: 110,
      },
      {
        text: "Vencimiento",
        value: "vencimiento",
        width: 110,
      },
      {
        text: "N° factura",
        value: "folio",
        width: 100,
      },
      {
        text: "Cédula",
        value: "cc_cliente",
        width: 110,
      },
      {
        text: "Cliente",
        value: "cliente",
        width: 100,
      },
      {
        text: "Tipo",
        value: "tipo",
        width: 100,
      },
      {
        text: "Pago",
        value: "metodo",
        width: 100,
      },
      {
        text: "Total",
        value: "total",
        width: 100,
      },
      {
        text: "",
        value: "nota",
        width: 100,
        sortable: false,
      },
      {
        text: "",
        value: "acciones",
        width: 100,
        sortable: false,
      },
    ],
    facturas: [],
  }),
  methods: {
    loadFacturas() {
      let search = this.search;

      if (this.search != '' && !isNaN(this.search)) {
        search = parseFloat(this.search);
      }

      this.loading_t = true;
      let body = {
        route: `/facturas?page=${this.page}`,
        params: {
          perPage: this.paginationServe.perPage,
          search: search,
        },
      };

      if (this.filter_date) {
        body.params.inicio = this.date.inicio;
        body.params.fin = this.date.fin;
      }
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.paginationServe.total = response.data.total;
            this.facturas = response.data.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    changePage(data) {
      this.facturas = [];
      this.page = data;
      this.loadFacturas();
    },
    setItemsPerPage(val) {
      if (val === -1) this.paginationServe.perPage = this.paginationServe.total;
      else this.paginationServe.perPage = val;

      this.loadFacturas();
    },
    downloadFacturas() {
      const name = "Listado Facturas";
      let data = this.lista.map((factura) => {
        return {
          Columna_A: factura.fecha,
          Columna_B: factura.vencimiento,
          Columna_C: factura.folio,
          Columna_D: factura.cliente,
          Columna_E: factura.cc_cliente,
          Columna_F: factura.metodo,
          Columna_G: factura.total,
          Columna_H: factura.notas,
        };
      });

      data.unshift(
        {
          Columna_A: "Fecha inicial",
          Columna_B: "",
          Columna_C: moment(this.date.inicio).format("ll"),
        },
        {
          Columna_A: "Fecha final",
          Columna_B: "",
          Columna_C: moment(this.date.fin).format("ll"),
        },
        {
          Columna_A: "",
        },
        {
          Columna_A: "Fecha factura",
          Columna_B: "Vencimiento",
          Columna_C: "Folio",
          Columna_D: "Cliente",
          Columna_E: "CC cliente",
          Columna_F: "Metodo pago",
          Columna_G: "Total",
          Columna_H: "# notas credito",
        }
      );
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    setFilterDates() {
      this.filter_date = !this.filter_date;
      console.log(this.filter_date);
    },
  },
  created() {
    this.loadFacturas();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      return this.facturas.map((factura) => {
        factura.created_at = moment(factura.created_at).format("D MMM YYYY");
        factura.fecha = moment(factura.fecha).format("D MMM YYYY");
        factura.vencimiento = moment(factura.vencimiento).format("D MMM YYYY");
        factura.metodo = factura.metodo == 1 ? "CONTADO" : "CREDITO";
        factura.folio = this.$options.filters.folio(factura.folio);
        factura.total = parseFloat(factura.total);
        return factura;
      });
    },
    lengthPagination() {
      function is_decimal(val) {
        if (val % 1 == 0) {
          return true;
        } else {
          return false;
        }
      }
      let number = this.paginationServe.total / this.paginationServe.perPage;
      if (!is_decimal(number)) number = parseInt(number) + 1;
      return number;
    },
  },
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
